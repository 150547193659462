import React from 'react';
import BlogClassicData from '../data/blog/BlogList.json';
import BlogDetailsContent from '../components/blog/BlogDetailsContent';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import HeaderBlog from '../common/header/HeaderBlog';
import FooterFour from '../common/footer/FooterFour';
import '../assets/scss/style.css';

// const BlogDetails = ({match: {params: {id}}}) => {
const BlogDetails = () => {
    // const blogId = parseInt(id, 10)
    // const data = BlogClassicData.filter(blog => blog.id === blogId);

    const data = BlogClassicData.filter(blog => blog.id === 1);
    return (
        <>
            <SEO title="Blog Details" />
            <main className="page-wrapper">
                <HeaderBlog btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />
                <div className="rn-blog-details-area">
                    <BlogDetailsContent data={data[0]}  />
                </div>

            </main>
            {/* <Layout>
                <div className="rn-blog-details-area">
                    <BlogDetailsContent data={data[0]}  />
                </div>
            </Layout> */}
            <FooterFour />
        </>
    )
}
export default BlogDetails;